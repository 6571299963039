.counter {
    width: 100%;
    height: 100%;
    transition: $trans;

    @include theme('light') {
        background: #f8faff;
    }

    @include theme('dark') {
        background: $colorDarkTheme2;
    }

    @include media(0, $mediaM) {
        @include theme('dark') {
            background: $colorDarkTheme3;
        }
    }

    &._white {
        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &._isEdit {
        @include theme('light') {
            background: $colorBlueActive;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &._end {
        border-radius: 0 8px 8px 0;
    }

    &._full {
        border-radius: 8px;

        @include media(0, $mediaM) {
            border-radius: 12px;
        }
    }

    &._grey {
        @include theme('light') {
            background: #f8f8f8;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &__btn {
        width: 40%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        transition: $trans;
        @include button(.9);

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        @include theme('light') {
            color: #9da8b4;
        }

        @include theme('dark') {
            color: #fff;
        }

        &._prev {
            padding-bottom: 2px;
            padding-left: 4px;
        }

        &._next {
            padding-right: 4px;
        }
    }

    &._isEdit &__btn {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._white &__btn {
        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__result {
        width: 30%;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        transition: $trans;

        @include media(0, $mediaM) {
            font-size: 18px;
        }

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &._isEdit &__result {
        @include theme('light') {
            color: $colorMain;
        }

        @include theme('dark') {
            color: $colorMainTheme;
        }
    }

    &._bigSize &__result {
        font-size: 16px;
    }

    &._grey &__btn {
        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }
    }
}
.chatVoiceBar {
    width: 100%;
    height: 100%;
    padding: 8px 14px;
    align-items: center;
    border-radius: 10px;

    @include forType('app') {
        border-radius: 12px;
    }

    @include theme('light') {
        background: #F1F4FF;
    }

    @include theme('dark') {
        background: #2f3b58;
    }

    &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $colorAlert;
        margin-right: 6px;
        animation: voicePin 1s infinite ease-in-out;

        @keyframes voicePin {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__timer {
        font-size: 15px;

        @include theme('light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }
}
.chatV2Message {
    position: relative;
    width: 100%;
    height: 100%;
    transition: $trans;

    @include forType("crm") {
        &__wrapper {
            padding-left: 0;

            &Box {
                padding-left: 40px;
            }
        }
    }

    &._me &__wrapper {
        padding-right: 34px;

        &Box {
            @include forType("crm") {
                padding-right: 40px;
                padding-left: 0;
            }
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        transition: $trans;

        &Box {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &._withMore:hover &__wrapper {
        transform: translate(-14px, 0);
    }

    &._me &__wrapperBox {
        align-items: flex-end;
    }

    &__avatar {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 32px;
        height: 32px;
        overflow: hidden;
        background: $colorBlueBack;
        border-radius: 50%;

        & .v2avatar__text {
            font-size: 12px;
        }
    }

    &._me &__avatar {
        right: 0;
        left: auto;
    }

    &__inner {
        position: relative;
        z-index: 1;
        max-width: 400px;
        height: 100%;
        padding: 10px 12px;

        // margin-right: 36px;
        overflow: hidden;
        transition: $trans;
    }

    &:not(._app) &__inner {
        // margin-right: 36px;
    }

    &._app &__inner {
        max-width: 292px;
        padding: 8px 16px;
    }

    &._app._me &__inner {
        max-width: 260px;
    }

    &._file &__inner {
        padding: 0;
        overflow: hidden;
    }

    &._info &__inner {
        width: 100%;
        max-width: 100%;
        padding: 0;
        background: none !important;
        border-radius: 0 !important;
    }

    &._image &__inner {
        background: none !important;
        border: 1px solid transparent;

        @include theme("light") {
            border-color: $colorBlue;
        }
    }

    &._me &__inner {
        background: linear-gradient(to bottom, #668dff, #3e6aed);
        border-radius: 16px 16px 0;
    }

    &:not(._me)._site &__inner {
        @include theme("light") {
            background: #fff;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }
    }

    ._fix &:not(._me)._site &__inner {
        @include theme("light") {
            background: #fafafa;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }
    }

    &._me._app &__inner {
        border-radius: 20px 20px 0;
    }

    &:not(._me) &__inner {
        background: $colorBlueBack;
        border-radius: 16px 16px 16px 0;
    }

    &:not(._me)._app &__inner {
        border-radius: 20px 20px 20px 0;

        @include theme("light") {
            background: #fff;
        }

        @include theme("dark") {
            background: #2b2f33;
        }
    }

    &._comments &__inner {
        background: #fffce8 !important;
    }

    &__creater {
        max-width: 100%;
        margin-bottom: 1px;

        &Inner {
            display: inline-block;
            max-width: 100%;
            margin-right: 6px;
            overflow: hidden;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            text-overflow: ellipsis;
        }

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &._me &__creater {
        color: #fff !important;
    }

    &._comments &__creater {
        color: $colorDark2 !important;
        font-size: 15px;
    }

    &._withCorporation {
        padding-left: 28px;
    }

    &__corporation {
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 22px;
        height: 22px;

        // width: calc(100% + 24px);
        // margin-top: 10px;
        // margin-left: -12px;
        // padding: 10px 12px 0 12px;
        // border-top: 2px solid #fff;

        & .manualCorporationInfo {
            height: 100%;
        }

        & .manualCorporationInfo__logo {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        font-size: 15px;
        line-height: 1.3;

        @include theme("light") {
            color: $colorDark2;
        }

        @include theme("dark") {
            color: #fff;
        }

        & b {
            margin-bottom: 4px;
            font-weight: 600;
            line-height: 1.2;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        & a {
            border-bottom: 1px solid transparent;
            user-select: text;

            @include theme("light") {
                color: $colorDark2;
                border-bottom-color: rgba($colorDark2, 0.72);
            }

            @include theme("dark") {
                color: #fff;
                border-bottom-color: rgba(#fff, 0.72);
            }
        }

        & ._cardNumber {
            display: inline-block;
            color: $colorMain;
            border-bottom: 1px solid rgba($colorMain, 0.5);
            transition: $trans;
            user-select: text;

            @include button(0.96);
        }
    }

    &._comments &__text {
        color: $colorDark2 !important;
        font-size: 14px;
    }

    &._app &__text {
        font-size: 16px;
        line-height: 1.4;
    }

    &._me &__text {
        @include theme("light") {
            color: #fff;
        }

        @include theme("dark") {
            color: #fff;
        }

        & a,
        & b {
            @include theme("light") {
                color: #fff;
            }

            @include theme("dark") {
                color: #fff;
            }
        }

        & a {
            @include theme("light") {
                border-bottom-color: rgba(#fff, 0.72);
            }

            @include theme("dark") {
                border-bottom-color: rgba(#fff, 0.72);
            }
        }

        padding-right: 20px;
    }

    &._me._app &__text {
        padding-right: 16px;
        font-weight: 600;
    }

    &__commentsInfo {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 14px;
        height: 14px;
        opacity: 0.32;
    }

    &__commentsSupport {
        margin-top: 2px;
        color: $colorDark2;
        font-weight: 300;
        font-size: 12px;
    }

    &._image &__read {
        right: 6px;
        bottom: 6px;
        z-index: 4;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background: rgba($colorMain, 0.8);
        border-radius: 7px;
    }

    &._file &__read {
        bottom: 9px;
    }

    &__read {
        position: absolute;
        right: 10px;
        bottom: 13px;
        z-index: 10;

        &Icon {
            width: 10px;
            height: 10px;
            transition: $trans;

            @include icon(#fff);

            & + & {
                margin-left: -5px;
            }

            &._hide {
                opacity: 0.24;
            }
        }
    }

    &__images {
        width: 100%;
    }

    &__image {
        position: relative;
        display: block;
        width: 100%;
        min-width: 80px;
        height: 260px;
        min-height: 80px;

        & .imageLazy__itemInner {
            object-fit: cover;
        }

        &Preview {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.32);
            opacity: 0;
            transition: $trans;

            &:hover {
                opacity: 1;
            }

            &Icon {
                width: 40px;
                height: 40px;
                margin: auto;

                @include icon(#fff);
            }
        }

        &:hover &Actions {
            opacity: 1;
        }

        &Actions {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 3;
            width: 26px;
            height: 26px;
            background: rgba(#000, 0.56);
            border-radius: 7px;
            opacity: 0;
            transition: $trans;

            @include button(0.96);

            &Icon {
                display: block;
                width: 20px;
                height: 20px;
                margin: auto;

                @include icon(#fff);
            }
        }
    }

    &__time {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        padding-bottom: 4px;
        font-weight: 300;
        font-size: 11px;
        text-align: right;
        transition: $trans;

        @include theme("light") {
            color: #9da8b4;
        }

        @include theme("dark") {
            color: $colorDarkThemeText;
        }
    }

    &__more {
        position: absolute;
        right: -8px;
        bottom: -1px;
        z-index: 3;
        width: 20px;
        height: 20px;
        transition: $trans;

        @include icon($colorGreyDark);
    }

    &._withMore:not(:hover) &__more {
        opacity: 0;
        pointer-events: none;
    }
}

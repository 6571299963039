.v2widgetField {
    display: flex;
    width: 100%;
    height: 100%;

    &__support {
        position: relative;
        padding: 7px 6px 0 0;
        font-size: 14px;
        white-space: nowrap;

        &Info {
            position: absolute;
            top: -4px;
            right: -4px;
            z-index: 3;
            width: 12px;
            height: 12px;
            cursor: pointer;

            @include icon(rgba($colorDark2, 0.3));

            &._alert {
                @include icon(rgba($colorAlert, 0.42));
            }
        }
    }

    &._checkboxesrow &__support {
        padding-top: 0;
    }

    &._typefile,
    &._typefiles:not(._filesRow),
    &._COL,
    &._checkboxescol,
    &._textarea:not(._inputRow) {
        flex-direction: column;
    }

    &._checkboxesrow {
        align-items: center;
    }

    &._typefile &__support,
    &._typefiles:not(._filesRow) &__support,
    &._COL &__support,
    &._checkboxescol &__support,
    &._textarea:not(._inputRow) &__support {
        margin-bottom: 8px;
        padding: 0;
    }

    &._typelist &__support,
    &._typefiles &__support {
        padding-top: 0;
    }

    &__box {
        position: relative;
        flex: 1;
        height: 28px;
        background: $colorBlueBack;
        border-radius: 7px;
        transition: $trans;

        &._empty {
            flex: none;
            width: 100%;
            height: auto;
            background: none;
            border-radius: 0;
        }
    }

    &._disabled .v2input__field {
        opacity: 0.56;
    }

    &._textarea:not(._inputRow) &__box {
        flex: none;
    }

    &._textarea:not(._calcHeight) &__box {
        height: 100px;
    }

    &._textarea._calcHeight &__box {
        height: auto;
    }

    &._typeline &__box {
        flex: none;
        width: 100%;
        height: 0;
        background: none;
        border-radius: 0;
    }

    &._typefile &__box {
        flex: none;
        width: 100%;
        height: 100%;

        @include button(0.98);

        border-radius: 9px;
    }

    &._typetext &__box {
        flex: none;
        width: 100%;
        height: 100%;
        background: none;
    }

    &._typechildren &__box {
        background: none;
    }

    &__loader {
        background: inherit;
        border-radius: inherit;

        & ._LOADERITEM {
            width: 16px;
            height: 16px;
        }
    }

    &._bigSize &__box {
        height: 34px;
        border-radius: 9px;
    }

    &._error &__box {
        background: $colorAlertBack;
    }

    &._typecheckboxes &__box,
    &._typecheckbox &__box,
    &._typelist &__box,
    &._typefiles &__box {
        height: auto;
        background: none;
        border-radius: 0;
    }

    &._typeswitch &__box {
        background: none;
        border-radius: 0;
    }

    &._error &__field .v2input__field {
        color: $colorAlert !important;
    }

    &._error &__field .v2input__support {
        color: rgba($colorAlert, 0.5) !important;
    }

    &._error &__fieldDate {
        @include icon($colorAlert);
    }

    &._error &__field._select::after {
        border-right-color: $colorAlert;
        border-bottom-color: $colorAlert;
    }

    &._error &__field .v2checkbox__box {
        background: rgba($colorAlert, 0.08) !important;
        border-color: rgba($colorAlert, 0.5) !important;
    }

    &._error &__field &__checkboxTitle {
        color: $colorAlert !important;
    }

    &._error &__field &__checkboxDescription {
        color: rgba($colorAlert, 0.64) !important;
    }

    &._bigSize &__field .v2input__field,
    &._bigSize &__field .v2input__support {
        padding: 0 14px;
        font-size: 15px;
    }

    &._bigSize &__field::after {
        right: 14px;
    }

    &._textarea &__field {
        & .v2input__field,
        & .v2input__support {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    &__field {
        position: relative;
        width: 100%;
        height: 100%;

        & .v2input__field,
        & .v2input__support {
            padding: 0 10px;
            font-size: 14px;
        }

        &._select .v2input__field {
            padding-right: 22px;
        }

        &._select::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            cursor: pointer;
            content: "";
        }

        &._select::after {
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -1px;
            border: 2px solid transparent;
            border-right-color: $colorDark2;
            border-bottom-color: $colorDark2;
            transform: translate(0, -50%) rotate(45deg);
            transition: $trans;
            content: "";
        }

        &Date {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            width: 32px;
            height: 100%;
            border-left: 2px solid #fff;

            &Icon {
                display: block;
                width: 18px;
                height: 18px;
                margin: auto;

                @include icon($colorGreyDark);
            }
        }

        &Emodzi {
            position: absolute;
            top: 7px;
            right: 7px;
            z-index: 2;
            width: 18px;
            height: 18px;

            @include button(0.95);
            @include icon($colorGreyDark);
        }
    }

    &__switch {
        margin: auto 0;
    }

    &__text {
        color: $colorDark2;
        font-weight: 300;
        font-size: 13px;
        line-height: 1.4;

        & b {
            font-weight: 500;
        }
    }

    &__checkbox {
        position: relative;
        display: block;
        width: 100%;
        padding-left: 24px;

        &Box {
            position: absolute;
            top: -1px;
            left: 0;
        }

        &Title {
            max-width: 100%;
            color: $colorDark2;
            font-size: 14px;
            line-height: 1.2;
            transition: $trans;
        }

        &Description {
            max-width: 100%;
            margin-top: 3px;
            color: $colorGreyDark;
            font-size: 13px;
            line-height: 1.3;
            transition: $trans;
        }
    }

    &__checkboxes {
        margin-bottom: -12px;
    }

    &__checkboxes._ROW &__checkbox {
        width: auto;
        margin: 0 12px 12px 0;
        padding-left: 22px;
    }

    &__checkboxes._COL &__checkbox {
        width: auto;
        margin-bottom: 12px;
    }

    &__file {
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 8px;
        cursor: pointer;
        user-select: none;

        &Input {
            display: none;
        }

        &:not(:hover) &PreviewInfo {
            opacity: 0;
        }

        &Preview {
            position: relative;
            z-index: 1;
            height: 100%;
            min-height: 34px;
            overflow: hidden;
            background: #fff;
            border-radius: 9px;
            aspect-ratio: 1/1;

            &Info {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.64);

                &Delete {
                    width: 18px;
                    height: 18px;
                    margin: auto;

                    @include icon(#fff);
                }
            }

            &Image {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                pointer-events: none;

                &Inner {
                    width: 100%;
                    height: 100%;
                }
            }

            &Icon {
                width: 18px;
                height: 18px;
                margin: auto;
                transition: $trans;

                @include icon($colorDark2);
            }
        }

        &._upload &PreviewIcon {
            opacity: 0;
        }

        &Content {
            padding-left: 10px;
        }

        &Support {
            margin-bottom: 5px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 14px;
        }

        &Info {
            color: $colorDark2;
            font-weight: 300;
            font-size: 12px;
            white-space: nowrap;
        }
    }

    &__list {
        position: relative;

        &Current {
            width: 100%;
            padding-right: 120px;
        }

        &Items {
            &._static {
                display: flex;
                flex-wrap: wrap;
            }

            &:not(._static) {
                margin-bottom: -6px;
            }
        }

        &Item {
            color: $colorGreyDark;
            font-size: 14px;
            white-space: nowrap;
        }

        &Items._static &Item {
            margin: 0 6px 6px 0;

            &:not(._empty) {
                margin-top: -1px;
            }
        }

        &Button {
            position: absolute;
            top: -6px;
            right: 0;
        }
    }

    &._typefiles._filesRow &__list {
        &Current {
            margin-top: -6px;
        }

        &Item._empty {
            padding-top: 6px;
        }
    }

    &._typefiles:not(._filesRow) &__list {
        &Current {
            padding-right: 0;
        }

        &Button {
            position: relative;
            top: 0;
            margin-top: 8px;
        }
    }

    &__action {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 28px;
        height: 28px;
        padding-right: 1px;
        background: $colorBlueActive;
        border-left: 2px solid #fff;
        border-radius: 0 7px 7px 0;

        &Icon {
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }

    &__mailing {
        width: 100%;

        &Button {
            position: relative;

            & + & {
                margin-left: 8px;
            }

            &Counter {
                position: absolute;
                top: -4px;
                right: -4px;
                z-index: 2;
                min-width: 18px;
                height: 18px;

                &._child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 2px 4px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 10px;
                    text-align: center;
                    background: $colorMain;
                    border: 2px solid #fff;
                    border-radius: 360px;
                }
            }
        }
    }
}

.chatWindowTags {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    &__inner {
        height: 28px;
        align-items: center;
        padding: 0 14px;
    }
    &__labels {
        &Inner {
            position: relative;
            transition: $trans;
            &._static {
                @extend %row;
            }
        }
    }
    &__label {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        white-space: nowrap;
        &:not(._show) {
            opacity: 0;
        }
    }
    &__labelsInner._static &__label {
        position: relative;
        margin-right: 4px;
        &:not(._show) {
            display: none;
            margin-right: 0;
        }
    }
    &__actions {
        margin-left: auto;
        position: relative;
        transition: $trans;
        &:not(._show) {
            opacity: 0;
        }
    }
    &__link {
        font-weight: 500;
        color: $colorMain;
        font-size: 13px;
        transition: $trans;
        @include button();
    }
    &__window {
        position: absolute;
        top: 34px;
        right: 17px;
        transition: $trans;
        z-index: 2;
        pointer-events: none;
        &:not(._show) {
            opacity: 0;
            transform: translate(0, 4px);
        }
    }
}
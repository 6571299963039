.chatPreview {
    display: block;
    width: 100%;
    height: 100%;
    padding: 9px 12px;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: $trans;

    &:hover {
        transform: translate(4px, 0);
    }

    @include theme("light") {
        background: #fcfcfc;
    }

    @include theme("dark") {
        background: $colorDarkTheme2;
    }

    @include forType("crm", "light") {
        background: #fbfcfd;
    }

    @include forType("crm", "dark") {
        background: $colorDarkTheme2;
    }

    @include forType("crm") {
        @extend %row;

        position: relative;
        align-items: center;
        padding-left: 12px;
        border-radius: 13px;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 2px;
            height: calc(100% - 24px);
            border-radius: 0 6px 6px 0;
            transform: translate(0, -50%);
            transition: $trans;
            content: "";
        }

        &._important::before {
            background: $colorAlert;
        }

        &._proccess::before {
            background: #ffc216;
        }

        &._complete::before {
            background: #5cc962;
        }
    }

    &._isClose:not(._current) {
        opacity: 0.64;

        @include forType("crm") {
            opacity: 1;
        }
    }

    .chatList._fix & {
        border: 0;

        @include theme("light") {
            background: #f8f8f8;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }
    }

    &._current {
        @include theme("light") {
            background: #fff;
            border-color: #c5d4ff;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
            border-color: $colorMainTheme;
        }

        @include forType("crm", "light") {
            border-color: #e1e8fc;
        }
    }

    &._support {
        background: #eaf1ff !important;
        border-color: #fff !important;
        border-width: 2px;

        &::before {
            // display: none;
        }
    }

    &__preview {
        position: relative;
        width: 52px;
        height: 52px;

        @include forType("crm") {
            width: 58px;
            height: 58px;
        }

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: $colorBlueBack;
            border-radius: 50%;
            transition: $trans;

            & .v2avatar__text {
                color: #d0d6de;
                font-weight: 600;
                font-size: 24px;
            }
        }
    }

    &__me {
        position: absolute;
        bottom: -4px;
        right: -4px;
        z-index: 3;
        display: flex;
        width: 24px;
        height: 24px;
        background: $colorMain;
        border: 2px solid $colorBlueBack;
        border-radius: 50%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Icon {
            display: block;
            width: 10px;
            height: 10px;
            margin: auto;

            @include icon(#fff);
        }
    }

    &._current &__previewInner {
        background: $colorBlueActive;

        & .v2avatar__text {
            color: $colorMain;
        }
    }

    &._support .v2avatar {
        background: #fff;
    }

    &__responsible {
        position: absolute;
        z-index: 2;
        overflow: hidden;
        border: 2px solid transparent;
        border-radius: 50%;
        transition: $trans;

        @include theme("light") {
            background: $colorGreyLight;
            border-color: $colorBlueBack;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
            border-color: $colorDarkTheme;
        }

        @include forType("crm", "light") {
            background: $colorBlueBack;
        }

        @include forType("crm") {
            right: -4px;
            bottom: -4px;
            width: 24px;
            height: 24px;
        }

        &._corporation {
            @include forType("crm") {
                right: auto;
                left: -4px;
            }
        }

        &:not(._show) {
            transform: scale(1.2);
            opacity: 0;
        }

        &Inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &Item {
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            & .v2avatar__text {
                font-size: 9px;
            }
        }

        &Icon {
            width: 14px;
            height: 14px;
            object-fit: contain;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;

        @include forType("crm") {
            width: calc(100% - 58px);
            height: auto;
            padding-right: 38px;
            padding-left: 14px;
        }
    }

    &._support &__content {
        // padding-right: 0 !important;
    }

    &__title {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        overflow: hidden;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: $trans;

        @include theme("light") {
            color: $colorDark;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &._current &__title {
        font-weight: 900;

        @include theme("light") {
            color: $colorMain;
        }

        @include theme("dark") {
            color: $colorMainTheme;
        }
    }

    &__message {
        position: relative;
        width: 100%;
        height: 17px;

        &Inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            font-weight: 500;
            font-size: 14px;

            // padding-right: 20px;
            line-height: 1.3;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: $trans;

            @include theme("light") {
                color: $colorDark;
            }

            @include theme("dark") {
                color: #fff;
            }

            &._number {
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 600;
                transform: translate(0, -4px);
                opacity: 0;

                @include theme("light") {
                    color: $colorMain;
                }

                @include theme("dark") {
                    color: $colorMainTheme;
                }

                &._show {
                    transform: translate(0, 0);
                    opacity: 1;
                }
            }
        }
    }

    &__type {
        // margin-top: auto;
        height: 13px;
        margin-top: 4px;

        @include forType("crm") {
            margin-top: 6px;
        }

        font-weight: 500;
        font-size: 12.5px;
        white-space: nowrap;

        @include theme("light") {
            color: #b8b8b8;
        }

        @include theme("dark") {
            color: $colorDarkThemeText;
        }

        @include forType("crm", "light") {
            color: $colorGreyDark;
        }
    }

    &._isClose &__typeInner {
        @include forType("site", "light") {
            color: #25c352;
        }

        @include forType("site", "dark") {
            color: #25c352;
        }
    }

    &__time {
        position: absolute;
        right: 0;
        bottom: 0;
        font-weight: 500;
        font-size: 11.5px;
        transition: $trans;

        @include theme("light") {
            color: #b8b8b8;
        }

        @include theme("dark") {
            color: $colorDarkThemeText;
        }

        @include forType("crm") {
            bottom: 1px;
            font-size: 11px;
        }
    }

    &._withCounter &__time {
        @include forType("site") {
            transform: translate(0, 0);
        }

        .chatList._fix & {
            @include forType("site") {
                transform: translate(03px, 0);
            }
        }
    }

    &__counter {
        position: absolute;
        z-index: 3;
        transition: $trans;

        @include forType("site") {
            right: 32px;
            bottom: -3px;
            min-width: 18px;
            height: 18px;
        }

        .chatList._fix & {
            @include forType("site") {
                right: 32px;
                bottom: -2px;
            }
        }

        @include forType("crm") {
            top: 8px;
            right: 24px;
            min-width: 18px;
            height: 18px;
        }
    }

    &._support &__counter {
        right: 12px !important;
    }

    &._choiceMode &__counter {
        opacity: 0;
        pointer-events: none;
    }

    &__more {
        position: absolute;
        top: 8px;
        right: 4px;
        z-index: 10;
        width: 20px;
        height: 20px;
        transition: $trans;

        @include icon($colorGreyDark);

        &Icon {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &._choiceMode &__more {
        opacity: 0;
        pointer-events: none;
    }

    &__choice {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        width: 18px;
        height: 18px;
        border: 1px solid #75808b;
        border-radius: 50%;
        transition: $trans;

        &._active {
            border-color: $colorMain;
        }

        &Point {
            width: 9px;
            height: 9px;
            margin: auto;
            background: $colorMain;
            border-radius: 50%;
            transition: $trans;
        }

        &:not(._active) &Point {
            opacity: 0;
        }
    }

    &:not(._choiceMode) &__choice {
        opacity: 0;
        pointer-events: none;
    }
}

.v2chatList {
    width: 100%;
    height: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__item {
        width: 100%;
        transition: $trans;
    }
}

.chatFix {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px;

    &._separate {
        padding: 0;
    }

    &__btn {
        @include button();
        margin: auto 0 0 auto;
        width: 60px;
        height: 60px;
        border-radius: 17px;
        background: $colorMain;
        justify-content: center;
        align-items: center;
        pointer-events: visible;
        position: relative;
        z-index: 3;
        transition: $trans;

        @include media(0, $mediaM) {
            width: 72px;
            height: 72px;
            border-radius: 20px;
        }

        @include theme('light') {
            box-shadow: 0 1px 7px 0 rgba(62, 106, 237, .56);
        }

        @include theme('dark') {
            box-shadow: 0 1px 10px 0 rgba($colorMain, 1);
        }

        &Icon {
            width: 28px;
            height: 28px;
            @include icon(#fff);

            @include media(0, $mediaM) {
                width: 36px;
                height: 36px;
            }
        }

        &Counter {
            position: absolute;
            top: -6px;
            left: -6px;
            min-width: 18px;
            height: 18px;
            z-index: 2;
        }
    }

    &__box {
        @extend %shadow;
        position: absolute;
        z-index: 2;
        right: 66px;
        bottom: 66px;
        width: 390px;
        max-height: 624px;
        height: calc(100% - 98px);
        pointer-events: visible;
        border-radius: 16px 16px 0 16px;
        overflow: hidden;
        transition: $trans;
        opacity: 0;
        transform: translate(8px, 0);

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include media(0, $mediaM) {
            z-index: 4;
        }

        &._show {
            opacity: 1;
            transform: translate(0, 0);

            @include media(0, $mediaM) {
                transform: scale(1);
            }
        }

        @include media(0, $mediaM) {
            width: 100%;
            height: 100vh;
            max-height: none;
            bottom: 0;
            right: auto;
            left: 0;
            transform: scale(1.05);
            border-radius: 0;
            box-shadow: none;
        }
    }

    &._separate &__box {
        position: relative;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &._fullScreen &__box {
        border-radius: 0;
        max-height: 100%;
    }

    &__loader {
        height: calc(100% - 98px);

        @include media(0, $mediaM) {
            z-index: 5;
        }

        &Icon {
            width: 30px;
            height: 30px;
        }
    }

    &._separate &__loader {
        height: calc(100% - 70px);
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 70px;
        padding: 16px;
        border-bottom: 1px solid transparent;
        position: relative;

        @include media(0, $mediaM) {
            padding: 12px $paddingMobile;
            height: 80px;
        }

        @include theme('light') {
            border-color: #ebebeb;
        }

        @include theme('dark') {
            border-color: $colorDarkTheme3;
        }
    }

    &__avatar {
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;

        @include media(0, $mediaM) {
            width: 56px;
            height: 56px;
            display: none;
        }

        @include theme('light') {
            background: #f4f7ff;
        }

        @include theme('dark') {
            background: $colorBorderHover;
        }

        &Logo {
            width: 28px;
            height: 28px;

            @include media(0, $mediaM) {
                width: 36px;
                height: 36px;
            }
        }
    }

    &__info {
        width: calc(100% - 42px);
        padding-left: 10px;

        @include media(0, $mediaM) {
            width: calc(100% - 56px);
            width: 100%;
            padding-left: 16px;
            padding-left: 0;
        }
    }

    &__close {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 16px;
        z-index: 2;
        width: 22px;
        height: 22px;
        @include icon(#e1e1e1);
    }

    &__name {
        max-width: 100%;
        margin-bottom: 5px;
        font-size: 17px;
        font-size: 1.7;
        font-weight: 900;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include media(0, $mediaM) {
            font-size: 18px;
            margin-bottom: 8px;
        }
    }

    &__status {
        position: relative;
        max-width: 100%;
        padding-left: 10px;
        font-size: 14px;
        font-weight: 500;

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        @include media(0, $mediaM) {
            font-size: 15px;
            padding-left: 16px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 1px;
            margin-top: 1px;
            background: #66ca49;
            width: 5px;
            height: 5px;
            border-radius: 50%;

            @include media(0, $mediaM) {
                width: 7px;
                height: 7px;
            }
        }
    }

    &__window {
        width: 100%;
        height: calc(100% - 70px);
        opacity: 0;
        transition: $trans;
        transform: translate(96px, 0);
        position: relative;
        z-index: 4;

        @include media(0, $mediaM) {
            height: calc(100% - 80px);
        }

        &._show {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &._separate &__window {
        transform: translate(0, 0);
    }

    &__list {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 70px);
        z-index: 3;
        opacity: 0;
        transition: $trans;
        transform: translate(-96px, 0);

        @include media(0, $mediaM) {
            height: calc(100% - 92px);
        }

        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        &._show {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}
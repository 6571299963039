.chatV2Rate {
    position: relative;
    align-items: center;
    padding: 22px 16px 12px 16px;
    border-radius: 12px;
    z-index: 2;

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: $colorDarkTheme3;
    }

    @include forType('crm', 'light') {
        background: $colorBlueBack;
    }

    ._fix & {
        @include theme('light') {
            background: #fafafa;
        }
    }

    &__icon {
        position: absolute;
        top: -22px;
        left: 50%;
        width: 34px;
        height: 34px;
        transform: translate(-50%, -50%);
        transition: $trans;
        object-fit: contain;

        @include forType('app') {
            width: 44px;
            height: 44px;
            top: -28px;
        }

        &._complete {
            width: 36px;
            height: 36px;
        }
    }

    &__content {
        align-items: center;

        &Inner {
            align-items: center;
        }
    }

    &__title {
        margin-bottom: 5px;
        font-size: 15.5px;
        font-weight: 900;
        text-align: center;
        white-space: nowrap;

        @include forType('app') {
            font-size: 16px;
        }

        @include theme('light') {
            color: $colorDark2;
        }

        @include forType('app', 'light') {
            color: $colorDark2;
        }

        @include theme('dark') {
            color: #fff;
        }
    }

    &__description {
        max-width: 100%;
        margin-bottom: 6px;
        font-size: 13.5px;
        font-weight: 500;
        white-space: nowrap;

        @include forType('app') {
            font-size: 14px;
        }

        @include theme('light') {
            color: #3e454c;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }
    }

    &__stars {
        align-items: center;
    }

    &__star {
        width: 18px;
        height: 18px;
        margin: 0 2px;

        @include forType('app') {
            width: 24px;
            height: 24px;
        }

        & .icon__fill {
            fill: none;

            @include theme('light') {
                stroke: #ffc216;
            }

            @include theme('dark') {
                stroke: $colorDarkThemeText;
            }

            @include forType('crm', 'light') {
                stroke: #ffc216;
            }
        }

        &._complete .icon__fill {
            fill: #ffc216;

            @include theme('light') {
                stroke: #ffc216;
            }

            @include theme('dark') {
                stroke: #ffc216;
            }
        }
    }

    &__btn {
        font-size: 13px;
        line-height: 1.3;
        font-weight: 500;
        color: $colorGreyDark;
        margin: 0 auto;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        transition: $trans;
        z-index: 1;
        position: relative;

        &._disabled {
            opacity: 0.32;
        }

        &:not(._show) {
            height: 0;
            opacity: 0;
        }

        & span {
            line-height: 1.3;
            color: $colorGreyDark;
            border-bottom: 1px dashed rgba($colorGreyDark, .7);
        }
    }
}
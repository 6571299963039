.chatList {
    width: 100%;
    height: 100%;
    position: relative;

    &__inner {
        width: 100%;
        height: 100%;
        transition: $trans;
        opacity: 0;
    }

    &._fix &__inner {
        @include theme('light') {
            background: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &._isReady &__inner {
        opacity: 1;
    }

    &__loader {
        padding-top: 36px;
    }

    &._fix &__loader {
        padding-top: 0;
        // padding-right: 96px;
    }

    &__head {
        width: 100%;
        height: 28px;
        margin-bottom: 8px;
        padding: 0 14px;

        @include forType('crm') {
            height: 32px;
            padding: 0 var(--paddingWidget);
        }
    }

    &._fix &__head {
        height: 62px;
        margin-bottom: 0;
        padding: 16px 16px 8px 16px;
    }

    &__search {
        width: 100%;
        height: 100%;

        @include forType('crm') {
            width: calc(100% - 40px);
        }
    }

    &__filter {
        width: 32px;
        height: 100%;
        margin-left: auto;
    }

    &__box {
        width: 100%;
        height: calc(100% - 36px);
        transition: $trans;
        position: relative;
        z-index: 2;

        @include forType('crm') {
            height: calc(100% - 40px);
        }

        &Scroll {
            @extend %noscroll;
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: auto;
            padding: 0 14px;

            @include forType('crm') {
                padding: 0 var(--paddingWidget);
            }
        }

        &Inner {
            width: 100%;
            margin-bottom: 20px;
        }

        &Loader {
            height: 100px;
            width: calc(100% - 8px);
        }
    }

    &._isChange &__box {
        // transform: scale(1.1);
        opacity: 0;
    }

    &._fix &__box {
        padding: 0 16px 0 16px;
        padding: 0;
        height: calc(100% - 62px);
    }

    &__items {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__item {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        transition: $trans;

        &Inner {
            @include button();
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }
    }

    ._parentForce &__item,
    &__items._static &__item {
        position: relative;

        &:not(._last) {
            margin-bottom: 8px;
        }

        &:not(._show) {
            display: none;
        }
    }

    &__items._static._fix &__item {
        &:not(._last) {
            margin-bottom: 4px;
        }
    }

    &._fix &__item {

        // border-bottom: 1px solid #ebebeb;
        & .chatPreview {
            padding-right: 12px;
        }
    }

    &__info {
        width: 100%;
        height: 100%;
        transition: $trans;
        position: relative;
        z-index: 3;
        opacity: 0;

        @include forType('crm') {
            // padding: 0 var(--paddingWidget);
        }

        &._show {
            opacity: 1;
        }
    }

    &:not(._empty) &__info._show {
        margin-top: 8px;
    }

    &._fix &__info {
        height: auto;
        flex-grow: 1;
        padding-top: 1px;
        margin-top: -16px;
    }

    &._fix._empty &__info {
        margin-top: 0;
    }

    &._isChange &__info {
        opacity: 0;
    }

    &__choices {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        padding: var(--paddingWidget);
        transition: $trans;
        background: linear-gradient(to bottom, rgba($colorBlueBack, 0), $colorBlueBack);

        &:not(._show) {
            opacity: 0;
            transform: translate(0, 6px);
        }

        &Inner {
            border-radius: 16px;
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 48px 0px rgba(62, 69, 76, 0.12);
        }

        &Head {
            width: 100%;
            align-items: center;
            margin-bottom: 14px;
        }

        &Title {
            font-size: 16px;
            font-weight: 600;
            color: $colorDark2;
            align-items: center;

            &Counter {
                font-size: 16px;
                font-weight: 500;

                &._parent {
                    margin-left: 2px;
                }
            }
        }

        &Delete {
            width: 100%;
            height: 44px;
        }
    }
}
.chatUserSettings {
    @extend %shadow;
    width: 100%;
    background: #fff;
    z-index: 1;
    border-radius: 14px;

    &__inner {
        width: 100%;
    }

    &__title {
        width: 100%;
        padding: 14px 16px;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 900;
        color: $colorDark2;
        border-bottom: 1px solid $colorBlue;
    }

    &__fields {
        width: 100%;
        padding: 14px 16px;
        border-bottom: 1px solid $colorBlue;
    }

    &__field {
        width: 100%;
        height: 42px;

        &+& {
            margin-top: 8px;
        }
    }

    &__content {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &Head {
            width: 100%;
            padding: 14px 16px;
            transition: $trans;
            border-bottom: 1px solid transparent;

            &Inner {
                width: 100%;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.3;
                color: $colorDark2;
                position: relative;
                transition: $trans;
                @include button(.98);

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%) rotate(45deg);
                    right: 0;
                    border: 2px solid transparent;
                    border-right-color: $colorDark2;
                    border-bottom-color: $colorDark2;
                    transition: $trans;
                }
            }
        }

        &._dropShow &Head {
            border-color: $colorBlue;

            &Inner::after {
                transform: translate(0, -50%) rotate(-135deg);
                margin-top: 3px;
            }
        }

        &SearchWrapper {
            width: 100%;
            position: relative;
            transition: $trans;
        }

        &Search {
            width: 100%;
            // height: px;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &SearchWrapper._static &Search {
            position: relative;

            &._show {
                margin-top: 8px;
            }

            &:not(._show) {
                display: none;
            }
        }

        &List {
            width: 100%;
            transition: $trans;
            position: relative;
            z-index: 1;

            &:not(._show) {
                opacity: 0;
            }

            &Box {
                width: 100%;
                position: relative;
                z-index: 1;
            }

            &Wrapper {
                @extend %noscroll;
                overflow-y: auto;
                width: 100%;
                transition: $trans;
                position: relative;
                z-index: 1;
            }

            &Item {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            ._parentCalc &Item,
            &._static &Item {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }

            &Loader {
                z-index: 100;

                &._loaderScroll {
                    height: 80px;
                }

                &._loader {
                    min-height: 100px;
                }
            }
        }

        &ListItem:not(._last) &Executor {
            border-color: $colorBlue;
        }

        &List._static &ExecutorAction {
            display: none;
        }

        &ListItem:not(._show) &Executor {
            opacity: 0;
        }

        &Executor {
            width: 100%;
            border-bottom: 1px solid transparent;
            transition: $trans;
            padding: 12px 16px;
            background: #fff;

            &._current {
                background: #F3F7FF;
            }

            &Inner {
                align-items: center;
                position: relative;
                width: 100%;
            }

            &Avatar {
                width: 36px;
                height: 36px;
                border-radius: 8px;
                background: $colorBlueBack;
                transition: $trans;
                position: relative;
                z-index: 1;
                overflow: hidden;

                & .avatar__item {
                    font-size: 18px;
                    font-weight: 500;
                    color: $colorGreyDark;
                }
            }

            &._current &Avatar {
                background: #fff;
            }

            &Current {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($colorMain, .6);
                transition: $trans;
                z-index: 2;

                &Icon {
                    width: 14px;
                    height: 14px;
                    @include icon(#fff);
                    margin: auto;
                }
            }

            &:not(._current) &Current {
                opacity: 0;
            }

            &Name {
                width: calc(100% - 36px);
                padding: 0 40px 0 12px;
                font-size: 14px;
                line-height: 1.3;
                color: $colorDark2;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;

                & b {
                    font-weight: 600;
                }
            }

            &Action {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 0;
                width: 30px;
                height: 30px;
                z-index: 2;
            }
        }
    }

    &__foot {
        width: 100%;
        border-top: 1px solid $colorBlue;
        padding: 14px 16px;
        align-items: center;

        &Button {
            &:not(._cancel) {
                width: 100%;
                height: 42px;
            }

            &+& {
                margin-top: 14px;
            }
        }
    }
}
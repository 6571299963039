.chat {
    align-items: stretch;
    width: 100%;
    height: 100%;
    position: relative;

    &__list {
        width: 260px;
        height: 574px;
        // padding: 0 20px 0 20px;
        border-radius: 0 0 0 14px;
        border-right: 1px solid transparent;

        @include theme('light') {
            background: #f3f3f3;
            border-color: #fff;
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
            border-color: $colorDarkTheme2
        }

        @include forType('crm') {
            height: 100%;
            width: 334px;
            padding-top: 20px;
            border-right: 0;
            padding-right: 0;
        }

        @include forType('crm', 'light') {
            background: none;
        }

        @include forType('crm', 'dark') {
            background: none;
        }
    }

    &__window {
        width: calc(100% - 260px);
        height: 574px;
        border-radius: 0 0 14px 0;
        position: relative;

        @include theme('light') {
            background: $colorGreyLight;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }

        @include forType('crm') {
            width: calc(100% - 334px - var(--paddingWidget));
            height: calc(100% - 40px);
            border-radius: 16px;
            box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
            z-index: 1;
            overflow: hidden;
            margin: auto auto auto 0;
        }

        @include forType('crm', 'light') {
            background: #fff;
        }

        @include forType('crm', 'dark') {
            background: #fff;
        }

        // & .empty {
        //     @include forType('crm') {
        //         border-radius: 0;
        //     }
        //     @include forType('crm', 'light') {
        //         background: #fbfcfd;
        //     }
        //     @include forType('crm', 'dark') {
        //         background: #fbfcfd;
        //     }
        // }
        &Info {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: $trans;

            &._show {
                opacity: 1;
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: $trans;
            z-index: 2;
            position: relative;

            &._show {
                opacity: 1;
            }
        }
    }
}
.chatFile {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 12px;
    transition: $trans;

    @include forType('app', 'light') {
        background: $colorBlueBack;
    }

    @include forType('app', 'dark') {
        background: #3E454C;
    }

    @include theme('light') {
        background: #fff;
    }

    @include theme('dark') {
        background: $colorDarkTheme2;
    }

    &._white {
        background: #fff !important;
        border: 1px solid rgba($colorBlue, .5);
    }

    &:not(._inMessage) {
        @include forType('crm', 'light') {
            background: $colorBlueBack;
        }

        @include forType('crm', 'dark') {
            background: #fff;
        }
    }

    &._inMessage {
        @include theme('light') {
            background: none;
        }

        @include theme('dark') {
            background: none;
        }
    }

    .chatV2Window._fix &:not(._inMessage) {
        @include theme('light') {
            background: #fafafa;
        }

        @include theme('dark') {
            background: $colorDarkTheme2;
        }
    }

    &:not(._inMessage):hover {
        // background: #eef1f5;
    }

    &__delete {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        transform: translate(50%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: $trans;

        @include media(0, $mediaM) {
            opacity: 1;
            visibility: visible;
            width: 24px;
            height: 24px;
            top: 6px;
            right: 6px;
        }

        @include theme('light') {
            background: #fff;
            box-shadow: 0 0 2px 0 rgba(173, 183, 197, 0.64);
        }

        @include theme('dark') {
            background: $colorDarkTheme3;
            box-shadow: 0 0 2px 0 rgba(#000, 0.5);
        }

        &Icon {
            width: 14px;
            height: 14px;
            @include icon($colorAlert);
        }
    }

    &:hover &__delete {
        visibility: visible;
        opacity: 1;
    }

    &__preview {
        position: relative;
        z-index: 2;
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 10px;

        @include forType('app', 'light') {
            background: #fff;
        }

        @include forType('app', 'dark') {
            background: #1C1F21;
        }

        @include forType('crm', 'light') {
            background: #fff;
        }

        @include media(0, $mediaM) {
            width: 38px;
            height: 38px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(#000, .3);
            content: "";
            display: none;

            @include forType('app') {
                display: none;
            }
        }
    }

    &._inMessage &__preview {
        background: #fff;

        @include forType('crm', 'light') {
            background: #fff;
        }

        @include forType('crm', 'dark') {
            background: #fff;
        }

        @include forType('app', 'light') {
            background: $colorBlueBack;
        }

        .chatV2Message._me & {
            @include forType('app', 'light') {
                background: #fff;
            }
        }

        .chatV2Window._fix & {
            @include forType('crm', 'light') {
                background: #fff;
            }

            @include forType('crm', 'dark') {
                background: #fff;
            }
        }

        &::before {
            display: none;
        }
    }

    &._inMessage._me &__preview {
        @include forType('crm', 'light') {
            background: #fff;
        }

        @include forType('crm', 'dark') {
            background: #fff;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        width: 18px;
        height: 18px;
        transform: translate(-50%, -50%);
        @include icon(rgba(#fff, .9));

        @include media(0, $mediaM) {
            width: 22px;
            height: 22px;
        }
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 22px;
        height: 22px;
        transform: translate(-50%, -50%);
        @include icon(#B5BDC5);
    }

    &__info {
        width: calc(100% - 32px);
        padding-left: 8px;

        @include media(0, $mediaM) {
            width: calc(100% - 38px);
        }
    }

    .chatV2Message._me &._inMessage &__size {
        padding-right: 26px;
    }

    .chatV2Message._me &._inMessage &__name {
        padding-right: 4px;
    }

    &__name {
        max-width: 80px;
        margin-bottom: 2px;
        overflow: hidden;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.2;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media(0, $mediaM) {
            font-size: 15px;
            max-width: 100%;
            padding-right: 10px;
        }

        @include theme('light') {
            color: $colorDark;
        }

        @include theme('dark') {
            color: #fff;
        }

        .chatV2Message._me & {
            color: #fff;
        }
    }

    &._inMessage &__name,
    &._default &__name {
        max-width: 100%;
    }

    &__size {
        max-width: 100%;
        font-size: 12px;
        font-weight: 300;

        @include media(0, $mediaM) {
            font-size: 13.5px;
        }

        @include theme('light') {
            color: #777;
        }

        @include theme('dark') {
            color: $colorDarkThemeText;
        }

        .chatV2Message._me & {
            color: #fff;
        }
    }

    &:not(._me) &__audio {
        &Button {
            @include theme('light') {
                background: $colorMain;
            }

            @include theme('dark') {
                background: rgba(#F5F7FA, .48);
            }

            &Icon {
                @include theme('light') {
                    @include icon(#fff);
                }

                @include theme('dark') {
                    @include icon($colorDark);
                }
            }
        }

        &Progress {
            &Line {
                @include theme('light') {
                    background: rgba(#3e454c, .24);
                }

                @include theme('dark') {
                    background: rgba(#fff, .24);
                }

                &Active {
                    @include theme('light') {
                        background: $colorDark;
                    }

                    @include theme('dark') {
                        background: #fff;
                    }

                    &Dot {
                        @include theme('light') {
                            background: $colorDark;
                        }

                        @include theme('dark') {
                            background: #fff;
                        }
                    }
                }
            }

            &Duration {
                @include theme('light') {
                    color: $colorDark;
                }

                @include theme('dark') {
                    color: #fff;
                }
            }
        }
    }

    &__audio {
        width: 100%;
        align-items: center;
        position: relative;
        padding: 8px 0 8px 40px;

        &Input {
            display: none;
        }

        &Button {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            position: relative;
            background: #fff;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;

            &Icon {
                width: 14px;
                height: 14px;
                @include icon($colorMain);
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }

                &._pause {
                    &:not(._show) {
                        transform: translate(-50%, -50%) rotate(-90deg);
                    }
                }

                &._play {
                    margin-left: 1px;

                    &:not(._show) {
                        transform: translate(-50%, -50%) rotate(90deg);
                    }
                }
            }
        }

        &Progress {
            min-width: 132px;
            width: 100%;
            padding-right: 6px;

            &Line {
                width: 100%;
                height: 2px;
                border-radius: 360px;
                background: rgba(#fff, .32);

                &Active {
                    width: 0;
                    height: 100%;
                    border-radius: 360px;
                    background: #fff;
                    position: relative;

                    &Dot {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(100%, -50%);
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #fff;
                        z-index: 2;
                    }
                }
            }

            &Duration {
                margin: 6px 0 -6px 0;
                font-size: 11px;
                color: #fff;
            }
        }
    }
}
.v2search {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 12px 0 32px;
    background: $colorBlueBack;
    border-radius: 10px;
    cursor: text;

    &__icon {
        position: absolute;
        top: 50%;
        left: 7px;
        width: 22px;
        height: 22px;
        transform: translate(0, -50%);

        @include icon($colorGreyDark);
    }

    &__field {
        width: 100%;
        height: 100%;
        padding-bottom: 1px;

        & .v2input__field,
        & .v2input__support {
            padding: 9px 0;
            font-size: 14px;
        }
    }
}

.chatV2Window {
    width: 100%;
    height: 100%;

    &__top {
        position: relative;
        z-index: 3;
        width: 100%;
        background: #fff;
        transition: $trans;
    }

    &__tags {
        position: absolute;
        top: 58px;
        left: 0;
        z-index: 3;
        width: 100%;
        background: #fff;
        transition: $trans;
    }

    &._loading &__top,
    &._loading &__tags {
        opacity: 0;
    }

    &._withTags &__info {
        top: 40px;
    }

    &__info {
        position: absolute;
        top: 12px;
        left: 50%;
        z-index: 2;
        align-items: center;
        padding: 8px;
        border-radius: 10px;
        transform: translate(-50%, 0);
        transition: $trans;

        @include media(0, $mediaM) {
            padding: 10px;
        }

        @include theme("light") {
            background: #fff;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }

        @include forType("crm", "light") {
            background: $colorBlueBack;
        }

        &:not(._show) {
            opacity: 0;
        }

        &Number {
            max-width: 100%;
            margin-bottom: 3px;
            font-weight: 600;
            font-size: 12px;
            line-height: 1.2;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 13px;
            }

            @include theme("light") {
                color: #9da8b4;
            }

            @include theme("dark") {
                color: $colorDarkThemeText;
            }

            &Link {
                display: inline-block;
                font-weight: 600;
                line-height: 1;
                border-bottom: 1px dashed transparent;

                @include theme("light") {
                    color: $colorMain;
                    border-color: rgba($colorMain, 0.7);
                }

                @include theme("dark") {
                    color: $colorMainTheme;
                    border-color: rgba($colorMainTheme, 0.7);
                }
            }
        }

        &Date {
            max-width: 100%;
            font-weight: 500;
            font-size: 12px;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 13px;
            }

            @include theme("light") {
                color: #9da8b4;
            }

            @include theme("dark") {
                color: $colorDarkThemeText;
            }
        }
    }

    &._fix &__info {
        @include theme("light") {
            background: #f9f9f9;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 36px;
        padding: 6px 16px;
        border-bottom: 1px solid transparent;

        @include theme("light") {
            border-color: #ebebeb;
        }

        @include theme("dark") {
            border-color: $colorDarkTheme2;
        }
    }

    &._fix &__head {
        @include theme("light") {
            border-color: #ebebeb;
        }

        @include theme("dark") {
            border-color: $colorDarkTheme3;
        }
    }

    &__back {
        align-items: center;

        &Icon {
            width: 14px;
            height: 14px;
            margin-right: 6px;

            @include theme("light") {
                @include icon($colorDark);
            }

            @include theme("dark") {
                @include icon(#fff);
            }
        }

        &Content {
            font-weight: 500;
            font-size: 14px;

            @include theme("light") {
                color: $colorDark;
            }

            @include theme("dark") {
                color: #fff;
            }
        }
    }

    &._withTags &__contentInner {
        // padding-top: 48px;
    }

    &._fix &__contentInner {
        padding-top: 30px;
    }

    &._app &__content {
        &Inner {
            max-height: calc(100% + 20px);
            padding: calc(20px + 20px) 20px;
        }
    }

    &__contentWrapper {
        width: 100%;
        transition: $trans;
    }

    &._withTags &__contentWrapper {
        padding-top: 30px;
    }

    &__contentWrapper._hide &__content {
        opacity: 0;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: $trans;

        &ScrollLoader {
            top: 0;
            bottom: auto;
            background: linear-gradient(to top, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%) !important;
        }

        &Inner {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            max-height: 100%;
            padding: 10px 20px 20px 20px;
            overflow: hidden;
            overflow-y: auto;
            // transition: $trans;
            // touch-action: none;
            -webkit-overflow-scrolling: none;
            overscroll-behavior: none;
            @extend %noscroll;

            &._hidden {
                overflow: hidden;
            }
        }

        &Messages {
            position: relative;
            z-index: 1;
            width: 100%;
            transition: $trans;
            // overflow: hidden;
            // flex-direction: column-reverse;
        }

        &Message {
            position: relative;
            justify-content: flex-end;
            width: 100%;
            transition: $trans;
            will-change: transform;

            &._inActive {
                opacity: 0.5 !important;
            }

            &._me {
                align-items: flex-end;
            }

            &._hide {
                height: 0;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                opacity: 0;
            }

            &Inner {
                width: 100%;
                transition: $trans;
            }

            &._first._date {
                margin-top: -20px;

                @include forType("app") {
                    margin-top: 0;
                }
            }

            &:not(._last) {
                margin-bottom: 6px;
            }

            &._date:not(._last) {
                margin-bottom: 20px;
            }

            &._disabled {
                margin-top: 12px;
            }

            &._date {
                width: calc(100% + 40px);
                margin-left: -20px;
            }

            &._prevDate:not(._last) {
                margin-top: -12px;
            }

            &._nextDate:not(:first-child) {
                margin-top: 12px;
            }
        }
    }

    &:not(._app)._loading &__foot {
        opacity: 0;
    }

    &._fix &__foot {
        @include theme("light") {
            background: #fff;
        }
    }

    &._fix &__footAction:not(._send) &__footActionInner {
        @include theme("light") {
            background: #fafafa;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }
    }

    ._ios &__foot {
        padding-bottom: 32px !important;
    }

    &__foot {
        position: relative;
        z-index: 2;
        width: 100%;
        margin-top: auto;
        padding: 14px;
        border-top: 1px solid transparent;
        transition: $trans;

        @include forType("app") {
            padding: 20px;
            border: 0;
            border-radius: 24px 24px 0 0;
            box-shadow: 0px 0px 3px 0px rgba(54, 60, 66, 0.2);
        }

        @include forType("app", "light") {
            background: #fff;
        }

        @include forType("app", "dark") {
            background: #2b2f33;
        }

        @include theme("light") {
            background: #f3f3f3;
            border-color: #ebebeb;
        }

        @include theme("dark") {
            background: $colorDarkTheme3;
            border-color: $colorDarkTheme2;
        }

        @include forType("crm", "light") {
            background: #fff;
        }

        &Supports {
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 3;
            width: 100%;
            padding: 20px;
            transition: $trans;
            pointer-events: none;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Support {
            position: relative;
            z-index: 1;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 56px;
            font-weight: 600;
            font-size: 16px;
            border-radius: 12px;
            pointer-events: visible;

            @include theme("light") {
                color: $colorMain;
                background: #fcfdfe;
            }

            @include theme("dark") {
                color: #aec2ff;
                background: #2b2f33;
            }

            & + & {
                margin-top: 8px;
            }

            &Loader {
                z-index: 4;
                background: inherit;
                border-radius: 12px;
            }
        }

        &Inner {
            position: relative;
            z-index: 2;
            align-items: flex-end;
            width: 100%;
            transition: $trans;
        }

        &._disabled &Inner {
            opacity: 0.5;
            pointer-events: none;
        }

        &Actions {
            position: relative;
            z-index: 3;
            height: 36px;
            margin-left: 6px;

            @include forType("app") {
                height: 40px;
                margin-left: 8px;
            }
        }

        &Action {
            position: relative;
            z-index: 1;
            width: 36px;
            height: 36px;
            transition: $trans;

            @include forType("app") {
                width: 40px;
                height: 40px;
            }

            &._send {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &._voice {
                z-index: 2;
            }

            &._voice._active &Inner {
                background: $colorMain !important;
            }

            &._send &Inner {
                background: $colorMain !important;
            }

            &:not(._send) &Inner {
                @include forType("crm") {
                    background: $colorBlueBack;
                }

                @include forType("site", "light") {
                    background: #fff;
                }

                @include forType("site", "dark") {
                    background: $colorDarkTheme2;
                }

                @include forType("app", "light") {
                    background: $colorBlueBack;
                }

                @include forType("app", "dark") {
                    background: #3e454c;
                }
            }

            &:not(._send) + &:not(._send) {
                margin-left: 6px;

                @include forType("app") {
                    margin-left: 8px;
                }
            }

            &._disabled &Inner {
                opacity: 0.64;
                pointer-events: none;
            }

            &Inner {
                position: relative;
                z-index: 2;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                transition: $trans;

                @include forType("app") {
                    border-radius: 12px;
                    cursor: default;
                }
            }

            &._voice:not(._active) &Back {
                background: rgba($colorMain, 0);
            }

            &Back {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                z-index: 1;
                width: 100%;
                height: 100%;
                background: rgba($colorMain, 0.5);
                border-radius: 12px;
                transform: translate(-50%, -50%);
                transition: $trans;
                animation: voice 0.9s infinite ease-in-out;
                pointer-events: none;

                @keyframes voice {
                    0% {
                        transform: translate(-50%, -50%) scale(1);
                    }

                    100% {
                        transform: translate(-50%, -50%) scale(1.6);
                        opacity: 0;
                    }
                }

                @include forType("app") {
                    animation: appVoice 1.2s infinite ease-in-out;

                    @keyframes appVoice {
                        0% {
                            transform: translate(-50%, -50%) scale(1);
                        }

                        100% {
                            transform: translate(-50%, -50%) scale(1.7);
                            opacity: 0;
                        }
                    }
                }

                &._1 {
                    animation-delay: 0s !important;
                }

                &._2 {
                    animation-delay: 0.3s !important;
                }

                &._3 {
                    animation-delay: 0.6s !important;
                }

                &._4 {
                    animation-delay: 0.9s !important;
                }
            }

            &Icon {
                display: block;
                width: 20px;
                height: 20px;
                margin: auto;
                @include icon(#b5bdc5);

                @include forType("app") {
                    width: 24px;
                    height: 24px;
                }
            }

            &._voice._active &Icon {
                @include icon(#fff !important);
            }

            &._send &Icon {
                width: 16px;
                height: 16px;

                @include forType("app") {
                    width: 20px;
                    height: 20px;
                }
            }

            &._send &Icon {
                @include icon(#fff);
            }

            &Loader {
                background: $colorMain;
                border-radius: 12px;

                &Item {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    &._fix &__area {
        @include theme("light") {
            background: #fafafa;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }
    }

    &__area {
        position: relative;
        flex: 2 auto;
        height: 36px;
        background: #fff;
        border-radius: 10px;
        transition: $trans;

        @include theme("light") {
            background: #fff;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }

        @include forType("crm", "light") {
            background: $colorBlueBack;
        }

        @include forType("app") {
            width: calc(100% - 96px);
            height: 40px;
            border: 1px solid #a3abb3;
            border-radius: 12px;

            &._voices {
                border-color: transparent;
            }
        }

        &._active {
            border-color: $colorMain;
        }

        &Voice {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            transition: $trans;

            @include forType("app") {
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
            }

            &:not(._show) {
                opacity: 0;
            }
        }

        &Input {
            width: 100%;
            height: 100%;
            padding: 8px 24px 6px 12px;
            font-size: 14.5px;
            line-height: 1.4;
            resize: none;
            @extend %noscroll;

            @include theme("light") {
                color: $colorDark2;
            }

            @include theme("dark") {
                color: #fff;
            }

            @include forType("app") {
                padding: 8px 14px 6px 14px;
                font-size: 16px;
            }

            &::-webkit-input-placeholder {
                @include theme("light") {
                    color: #9da8b4;
                }

                @include theme("dark") {
                    color: $colorDarkThemeText;
                }

                @include forType("app", "dark") {
                    color: #a3abb3;
                }
            }
        }

        &Emodzi {
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 2;
            width: 16px;
            height: 16px;
            transition: $trans;
            @include button(0.96);

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &__rate {
        align-items: center;
        width: 100%;

        &Inner {
            padding: 20px 0 10px 0;
        }
    }

    &._fix &__dateInner {
        @include theme("light") {
            background: #fafafa;
        }

        @include theme("dark") {
            background: $colorDarkTheme2;
        }
    }

    &__date {
        align-items: center;
        width: 100%;
        padding: 12px 0;

        &Inner {
            padding: 6px 12px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 14px;
            border-radius: 360px;

            @include theme("light") {
                color: #9da8b4;
                background: #fff;
            }

            @include theme("dark") {
                color: $colorDarkThemeText;
                background: $colorDarkTheme3;
            }

            @include forType("crm", "light") {
                background: $colorBlueBack;
            }

            @include forType("app", "light") {
                background: #fff;
            }
        }
    }

    &__files {
        position: relative;
        z-index: 1;
        width: calc(100% + 8px);
        margin-left: -4px;
        transition: $trans;

        &:not(._empty) {
            padding-bottom: 10px;
        }

        &._static {
            flex-wrap: wrap;
            @extend %row;
        }
    }

    &__file {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% / 4);
        padding: 0 4px;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            width: 100%;
        }
    }

    &__files._app &__file {
        width: 50%;
    }

    &__files._static &__file {
        position: relative;
        margin-bottom: 6px;

        &:not(._show) {
            display: none;
        }
    }

    &__session {
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 9px 0;
        text-align: center;
        background: $colorBlueBack;

        @include forType("app", "light") {
            background: #fff;
        }

        @include forType("app", "dark") {
            background: #2b2f33;
        }

        &Inner {
            color: $colorGreyDark;
            font-size: 12.5px;

            @include forType("app", "light") {
                color: $colorGreyDark;
            }

            @include forType("app", "dark") {
                color: $colorGreyDark;
            }

            & b {
                font-weight: 600;
            }
        }
    }
}

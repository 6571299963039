.v2avatar {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 9px;

    &._notRad {
        border-radius: 0;
    }

    &._grey {
        background: $colorBlueBack;
    }

    &._circle {
        border-radius: 50%;
    }

    &__inner {
        display: flex;
        width: 100%;
        height: 100%;
        transition: $trans;

        & .v2lazyImage__loader {
            width: 16px;
            height: 16px;
        }
    }

    &__text {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $colorGreyDark;
        font-size: 16px;
        transition: $trans;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
